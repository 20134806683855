.image-container {
  display: flex;
  justify-content: center; 
  width: 100%;
  overflow-x: hidden;
}

.image-row {
  display: flex;
  justify-content: center; 
  position: relative; 
}

.team-image {
  filter: grayscale(100%);
  margin: 0 10px;
  height: 469px;
  width: auto;
  transition: transform 1s ease;
  
}

.img1 {
  z-index: 1;
}
.img2 {
  z-index: 2;
}
.img3 {
  z-index: 3;
}
.img4 {
  z-index: 2;
}
.img5 {
  z-index: 1;
  
}


@media (max-width: 1200px) {
  .team-image {
    height: 300px;
  }
}

@media (max-width: 992px) {
  .team-image {
    height: 250px;
  }
}

@media (max-width: 768px) {
  .team-image {
    height: 200px;
  }
}

@media (max-width: 576px) {
  .team-image {
    height: 150px;
  }
}


@media (max-width: 425px) {
  .team-image {
    height: 100px;
  }
}
