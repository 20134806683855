
.main-contactus{
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    width: 100vw;
    /* height: 55rem; */
    border-top-left-radius:5rem;
    border-top-right-radius:5rem;

    font-family: Montserrat;
    margin-top: -200px;
    /* transform: translateY(-200px); */
    box-sizing: border-box;
    padding: 80px 150px;
}
.heading-contactus{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-left: 5rem;
    padding-top: 3rem;
    font-size: 3rem;
    @media (max-width: 768px) {
        justify-content: center;
        margin-left: 0;
        font-size: 2rem;
    }
}
.contactus-form{
    /* width: 100%; */
    display: flex;
    height: 80%;
    flex-direction: column;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
    
    @media (max-width: 768px) {
       /* height: 70%; */
        margin-top: 4rem;
    }
      @media (max-width: 421px) {
        /* width: 120%; */

    }
}
.dream-heading{
    font-size: 2rem;
    font-weight: 500;
    @media (max-width: 768px) {
        /* width: 160%; */
        font-size: 1.5rem;
    }
    @media (max-width: 421px) {
        /* width: 160%; */
        font-size: 1rem;
    }
}
.contact-form{
    display: flex;
    width: 45rem;
    width: 100%;

    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    @media (max-width: 768px) {

        /* width: 100%; */

    }
       @media (max-width: 600px) {

        width: 100%;

    }
       @media (max-width: 500px) {

        /* width: 200%; */
        width: 100%;

    }
}
.input-sec-1{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 1rem;
    gap: 12px;
    max-width: 800px;
    @media (max-width: 768px) {
        width: 100%;
       flex-direction: column;
       align-items: center;
       margin-bottom: 0.5rem;
    }
}
.input-1{
    width: 100%;
    padding-left: 10px;
    height: 3rem;
    border-radius: 8px;
    border: none;
    box-sizing: border-box;

background: rgba(238, 238, 238, 0.70);

backdrop-filter: blur(25px);
@media (max-width: 768px) {
    /* width: 96%; */
    /* margin: 0.5rem 0; */
 }
        @media (max-width: 600px) {

        /* width: 110%; */

    }
       @media (max-width: 421px) {
        height: 2rem;
        /* width: 150%; */

    }
}
.input-2{
    box-sizing: border-box;
    width: 100%;
    height: 17rem;
      border-radius: 8px;
    padding-left: 10px;
    padding-top: 10px;
    border: none;
backdrop-filter: blur(25px);
background: rgba(238, 238, 238, 0.70);
    max-width: 800px;
    @media (max-width: 768px) {
    /* width: 96%; */
    width: 100%;
        height:12rem ;
    margin: 0.5rem 0;
 }
       @media (max-width: 600px) {
        height:7rem ;
        /* width: 110%; */

    }
     @media (max-width: 421px) {
        height:5rem ;
        /* width: 150%; */

    }
}

.input-1:focus,
.input-2:focus{
    outline: 2px solid rgba(0, 0, 0, 0.5);
}

.input-1::placeholder,
.input-2::placeholder{
    font-family: Montserrat;
    font-size: 14px;
}

.contact-us-submit {
    margin-top: 1rem;
    width: 100%;
    /* height: 3rem; */
    border: none;
    border-radius: 8px;
    padding: 10px;
    background: linear-gradient(89deg, rgba(33, 33, 33, 0.90) -0.1%, rgba(102, 102, 102, 0.90) 100%);
    color: #FFFFFF;

    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(25px);
    transition: background-color 0.3s, border 0.3s; /* Add smooth transition for hover effects */
    max-width: 800px;
           @media (max-width: 600px) {

        width: 100%;

    }
          @media (max-width: 421px) {
        /* height: 2rem; */
        /* width: 150%; */

    }
}

.contact-us-submit:hover {
    cursor: pointer;
    background-color: #333; 
    /* border: 2px solid #FFFFFF; Correct border specification */
}

@media screen and  (max-width: 500px) {
    .main-contactus{
        padding: 40px 40px;
        border-radius: 40px 40px 0 0;
    }
    .contactus-form{
        margin-top: 50px;
    }
}

@media screen and (max-width: 1400px) {
    .main-contactus{
        padding: 60px 100px;
    }
}

@media screen and (max-width: 800px) {
    .main-contactus{
        padding: 50px 50px;
    }
}

@media screen and (max-width: 500px) {
    .main-contactus{
        padding: 40px 40px;
    }
    .input-1,
    .input-2{
        font-size: 12px;
    }
    .contact-us-submit{
        font-size: 14px;
        padding: 10px;
    }
}
