@import '/src/Styles.scss';

.section-technologies {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 484px;
    padding: 100px 0;
    background: #F8F8F8;
    // gap: 128px;
    transform: translateY(-100px);
    z-index: 0;
    align-items: center;
    overflow-x: hidden;
    box-sizing: border-box;
    width: 100vw;
    position: sticky;
    white-space: nowrap;
}

@keyframes slide {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

.tech-slide {
    display: inline-block;
    animation: 20s slide infinite linear;
    // gap: 128px;
}

.section-technologies img{
    margin: 0 64px;
}

@media screen and  (max-width: 1400px) {
    .section-technologies {
        // gap: 100px;
        height: 400px;
    }
    .section-technologies img{
        height: 100px;
    }
}

@media screen and  (max-width: 800px) {
    .section-technologies {
        // gap: 80px;
        height: 380px;
    }
    .section-technologies img{
        height: 70px;
        margin: 0 32px;
    }
}

@media screen and  (max-width: 500px) {
    .section-technologies {
        // gap: 60px;
        height: 320px;
    }
    .section-technologies img{
        height: 50px;
    }
}