.glass-background {
  display: flex;
  padding: 8px 8px 32px 8px;
  flex-direction: column;
  width: 150px;
  height: 150px;
  height: fit-content;
  align-items: flex-center;
  border-radius: 20px;
  background: linear-gradient(
    108deg,
    rgba(255, 255, 255, 0.26) 0%,
    rgba(255, 255, 255, 0.07) 100%
  );
  backdrop-filter: blur(19.168039321899414px);
}
.number-typo {
  color: var(--Primary-Font-Color, #eaeaea);
  text-align: center;
  // font-family: Inter;
  font-size: 20.446px;
  font-style: normal;
  font-weight: 500;
  text-align: end;
  line-height: normal;
}

.letter-typo {
  color: var(--Primary-Font-Color, #eaeaea);
  text-align: center;
  font-family: Montserrat;
  font-size: 6vmax;
  font-style: normal;
  font-weight: 700;
  line-height: 94px;
}

@media screen and (max-width: 900px) {
  .quote-typo {
    font-size: 4vmax;
  }
}

@media screen and (max-width: 720px) {
  .glass-background {
    display: flex;
    padding: 8px 8px 10px 8px;
    flex-direction: column;
    width: 80px;
    height: 80px;
    align-items: flex-center;
    border-radius: 14px;
  }
  .number-typo {
    font-size: 1vmax;
    line-height: 4px;
  }

  .letter-typo {
    font-size: 4vmax;
    line-height: 73px;
  }
}

@media screen and (max-width: 500px) {
  .glass-background {
    width: 50px;
    height: 50px;
  }
  .letter-typo {
    font-size: 4vmax;
    line-height: 46px;
  }
}
