@import '/src/Styles.scss';

.about-container {
  background-color: #000000;
  color: #fff;
  padding: 60px 40px;
  border-top-left-radius: 100px; 
  border-top-right-radius: 100px; 
  width: 100%;
  margin: 0;
  overflow-x: hidden;
  // position: relative;
  transform: translateY(-200px);
  // z-index: 20;

  @media (min-width: 800px) {
     padding: 60px 50px;
  }

  @media (min-width: 1100px) {
     padding: 60px 60px;
  }

  @media (min-width: 1400px) {
    padding: 60px 80px;
  }

  @media (min-width: 1800px) {
     padding: 60px 100px;
  }

  .about-content {
    font-family: Montserrat, sans-serif;
    line-height: 1.6;
    text-align: left;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0 10px; // Default for mobile
    display: flex;
    flex-direction: column;

    @media (min-width: 800px) {
      padding: 0 15px;
    }

    @media (min-width: 1100px) {
      padding: 0 20px;
    }

    @media (min-width: 1400px) {
      padding: 0 25px;
    }

    @media (min-width: 1800px) {
      padding: 0 30px;
    }

    .heading-about {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      // margin-left: 20px; // Default for mobile
      color: #f2f2f2;
      font-size: 24px; // Default for mobile
      font-weight: 700;

      @media (min-width: 577px) {
        font-size: 32px;
        // margin-left: 50px;
      }

      @media (min-width: 800px) {
        font-size: 36px;
        // margin-left: 60px;
      }

      @media (min-width: 1100px) {
        font-size: 42px;
        // margin-left: 80px;
      }

      @media (min-width: 1400px) {
        font-size: 48px;
        // margin-left: 100px;
      }

      @media (min-width: 1800px) {
        font-size: $heading-font;
        // margin-left: 120px;
      }

      @media (min-width: 993px) {
        font-size: $heading-font;
        // margin-left: 173px;
      }

      .underline::after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: #fff;
        position: absolute;
        bottom: -2px;
        left: 0;
      }
    }

    p {
      position: relative;
    }

    .quote-section {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      width: 100%;

      .quote-text {
        color: #fff;
        text-align: center;
        font-size: 12px; // Default for mobile
        font-weight: 300;
        line-height: normal;
        width: 90%;
        max-width: 1438px;
        white-space: pre-line;

        @media (min-width: 577px) {
          font-size: 24px;
        }

        @media (min-width: 800px) {
          font-size: 26px;
        }

        @media (min-width: 1100px) {
          font-size: 28px;
        }

        @media (min-width: 1400px) {
          font-size: 32px;
        }

        @media (min-width: 1800px) {
          font-size: 36px;
        }

        @media (min-width: 993px) {
          font-size: 36px;
        }

        .bold {
          font-weight: 600;
        }
      }

      .quote-icon-flip {
        width: 40px; 
        height: 40px; 
        align-self: flex-start;
        margin-bottom: 20px;
      }

      .quote-icon {
        width: 40px; 
        height: 40px; 
        align-self: flex-end;
        margin-top: 20px;
      }
    }
  }
}

@media screen and  (max-width: 500px) {
  section#about{
      padding: 40px 40px;
      border-radius: 40px;
  }
}