$black: #000000;
$white: #ffffff;

$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;

$hero-font: 122px;
$heading-font: 64px;
$card-title: 24px;
$card-content: 20px;