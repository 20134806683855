@import '/src/Styles.scss';
.solution {
    display: flex;
    align-items: center;
    flex-direction: column;
    // min-width: 525px;
    height: 650px;
    box-sizing: border-box;
    border-radius: 75px;
    padding: 60px 40px;
    gap: 12px;
    margin: 24px;
    // align-self: center;
    // justify-self: center;
}

.solution .title {
    font-size: $card-title;
    font-weight: $bold;
}

.solution .content {
    font-size: $card-content;
    font-weight: $regular;
}

@media screen and  (max-width: 1800px) {
    .solution {
        // max-width: 425px;
        height: 580px;
    }
    .solution img{
        height: 200px;
        width: auto;
    }
}

@media screen and  (max-width: 1580px) {
    .solution img{
        height: 300px;
        width: auto;
    }
}

@media screen and  (max-width: 1400px) {
    .solution {
        max-width: 350px;
        height: 450px;
        border-radius: 50px;
    }
    .solution img{
        height: 150px;
        width: auto;
    }
    .solution .title {
        font-size: 20px;
    }
    
    .solution .content {
        font-size: 16px;
    }
}

@media screen and  (max-width: 1100px) {
    .solution {
        min-width: 300px;
        height: 390px;
        border-radius: 50px;
        padding: 40px 20px;
    }
    .solution img{
        height: 140px;
        width: auto;
    }
    .solution .title {
        font-size: 18px;
    }
    
    .solution .content {
        font-size: 14px;
    }
}

@media screen and  (max-width: 800px) {
    .solution {
        min-width: 280px;
    }
//     .solution {
//         min-width: 260px;
//         height: 315px;
//         border-radius: 50px;
//         padding: 40px 20px;
//     }
//     .solution img{
//         height: 135px;
//         width: auto;
//     }
//     .solution .title {
//         font-size: 12px;
//     }
    
//     .solution .content {
//         font-size: 10px;
//     }
}