@import "/src/Styles.scss";

.home {
  display: flex;
  flex-direction: column;
  align-items: start;
  min-height: 100vh;
  background: $black;
  // overscroll-behavior: none;
}

section {
  padding: 80px 150px;
  box-sizing: border-box;
}

.main-container {
  // display: flex;
  // flex-direction: column;
  // background-image: url("../assets/backgrounds/background_1.png");
  // background-size: fit;
  // background-position: top;
  // height: 110dvh;
}
.quote-typo {
  display: flex;
  flex-direction: row;
  color: var(--Primary-Font-Color, #eaeaea);
  text-align: start;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-size: 6vmax;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
}

.quote-container {
  display: flex;
  // margin-top: 300px;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  width: 100vw;
  background-image: url("../assets/backgrounds/background_1.png");
  background-size: cover;
  background-position: top;
  justify-content: center;
  height: 100vh;
}
@media screen and (max-width: 720px) {
  .quote-typo {
    font-size: 3vmax;
  }
}

@media screen and (max-width: 500px) {
  .quote-container {
    height: 70vh;
  }
}
