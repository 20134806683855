header {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 100;
}

header nav {
  display: inline-flex;
  height: 58px;
  width: 80%;
  margin-top: 45px;
  position: relative;
  padding-inline: 10px;
  max-width: 547px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 40px;
  border: 1px solid #979797;
  background: linear-gradient(
    89deg,
    rgba(33, 33, 33, 0.5) -0.1%,
    rgba(102, 102, 102, 0.5) 100%
  );
  backdrop-filter: blur(25px);
  transition: height 0.3s ease 0.6s;
}

header nav ul {
  margin: 0;
  padding-inline: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

header nav ul li {
  list-style-type: none;
}

header nav ul li a {
  color: #dbdbdb;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-inline: 10px;
  text-decoration: none;
}

.logo {
  color: #dbdbdb;
  font-family: "JetBrains Mono";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-spacing: -10px;
  text-decoration: none;
  padding-inline: 10px;
  white-space: nowrap;
}

.logo:hover {
  cursor: pointer;
  color: white;
}

header nav ul li a:hover {
  cursor: pointer;
  color: white;
}

.mobile-logo {
  display: none;
  position: absolute;
  left: 27px;
  top: 20px;
  z-index: 100;
  img {
    width: 25px;
  }
  opacity: 0.6;
}

.mobile-logo-dcode {
  display: none;
  position: absolute;
  right: 30px;
  top: 20px;
  color: #dbdbdb;
  font-family: "JetBrains Mono";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-spacing: -10px;
  text-decoration: none;
  padding-inline: 10px;
  white-space: nowrap;
}

@media screen and (max-width: 560px) {
  header nav {
    height: 45px;
  }

  header nav ul li a {
    font-size: 15px;
  }

  .logo {
    font-size: 15px;
    word-spacing: -7px;
  }
}

@media screen and (max-width: 500px) {
  header nav {
    display: inline-flex;
    height: 65px;
    width: 100vw;
    margin-top: 0px;
    padding-inline: 10px;
    border-radius: 0;
    border: none;
    z-index: 100;
    position: absolute;
    transform: translateX(-50%);
    box-sizing: border-box;
    overflow-x: hidden;
  }

  .mobile-logo-dcode {
    display: block;
  }

  nav.open ul li:nth-child(2) {
    transform: translate(100%, 0%);
    transition: transform 0.3s ease-in 0.4s;
  }
  nav.open ul li:nth-child(3) {
    transform: translate(100%, 0%);
    transition: transform 0.3s ease-in 0.3s;
  }
  nav.open ul li:nth-child(4) {
    transform: translate(100%, 0%);
    transition: transform 0.3s ease-in 0.2s;
  }

  nav:not(.open) ul li:nth-child(2) {
    transform: translate(0%, 0%);
    transition: transform 0.3s ease-in 0.2s;
  }
  nav:not(.open) ul li:nth-child(3) {
    transform: translate(0%, 0%);
    transition: transform 0.3s ease-in 0.3s;
  }
  nav:not(.open) ul li:nth-child(4) {
    transform: translate(0%, 0%);
    transition: transform 0.3s ease-in 0.4s;
  }

  header nav:not(.open) {
    transition: height 0.5s ease;
    height: 100vh;
  }

  header nav ul li {
    height: 70px;
  }

  header nav ul {
    width: 100vw;
    display: flex;
    flex-direction: column;
  }

  .mobile-logo {
    display: flex;
  }

  .logo {
    display: none;
    font-size: 28px;
    word-spacing: -12px;
  }

  header nav ul li a {
    font-size: 25px;
  }

  nav ul li {
    transform: translate(0, 0);
  }
}
