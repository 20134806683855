@import '/src/Styles.scss';

.solutions {
    display: flex;
    flex-direction: column;
    // gap: 44px;

    // overflow: hidden;
    // will-change: transform;
    // scroll-behavior: smooth;
    // padding-right: 100px;
}

.section-solutions{
    // height: 968px;
    // height: 2600px;
    padding: 0px;
    // background: #000000;
    background: $white;
    width: 100vw;
    position: relative;
    padding: 80px 150px;
    border-radius: 100px;
    // height: 100vh;
    z-index: 1;
    box-sizing: border-box;
    // overflow: hidden;
}

.swiper-nav-btns{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    bottom: 300px;
    position: absolute;
    box-sizing: border-box;
    padding: 80px 150px;
    z-index: 1;
    // top: 0;
  left: 0;
  right: 0;
//   bottom: 0;
}

.prev-arrow{
    left: 0px;
    width: 120px;
    height: 120px;
    background: #f3f3f3;
    border-radius: 100px;
    font-size: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: -74px;
}
.next-arrow{
    right: 0px;
    width: 120px;
    height: 120px;
    font-size: 60px;
    background: #f3f3f3;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: -74px;
}

// .swiper-slide{
//     align-items: center;
//   justify-content: center;
//   display: flex;
//   margin: auto;
// }

// .sticky-wrapper::before,
// .sticky-wrapper::after {
//     content: '';
//     position: absolute;
//     width: 100px;
//     height: 100px;
//     top: 0;
// }

// .sticky-wrapper::before {
//     left: 0;
//     background: $black;
//     z-index: -1;
// }

// .sticky-wrapper::after {
//     right: 0;
//     background: $black;
//     // z-index: 0;
//     z-index: -1;
// }
.swiper-scrollbar{
    display: none;
}

.solutions .solution:first-child{
    margin-left: 400px;
}

.section-solutions .sticky-wrapper {
    // position: sticky;
    top: 0;
    width: 100vw;
    border-radius: 100px;
    background: $white;
    z-index: 1;
    padding: 80px 150px;

    // overflow-x: hidden;
    // backdrop-filter: blur(0px);
    box-sizing: border-box;
    height: 100vh;
    // margin-top: -100px;
    // transform: translateY(-100px);
    // max-width: 100%;
}

.empty-box {
    height: 100px;
    width: 100vw;
    position: absolute;
    bottom: 0;
    z-index: -1;
    margin-left: -150px;
    background: #F8F8F8;;
}

.empty-box:first-child {
}

.empty-box:last-child {
    background-color: rgb(255, 255, 255);
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
}

.section-solutions .dynamic-item {
    position: absolute;
    left: 0;
}

.dynamic-item.item1 {
    bottom: -15%;
}
.dynamic-item.item2 {
    left: 30%;
    top: 15%;
}
.dynamic-item.item3 {
    left: 50%;
    top: 40%;
}
.dynamic-item.item4 {
    left: 70%;
    top: 75%;
}
.dynamic-item.item5 {
    left: auto;
    right: 0;
    top: 0;
}

.section-solutions .solutions {
    // margin-left: 500px;
    margin-top: 80px;
}

.section-title{
    display: flex;
    font-size: $heading-font;
    font-weight: $bold;
    color: $black;
    position: relative;
    width: fit-content;
    margin-bottom: 50px;
}

.section-title::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -12px; 
    width: 80%;
    height: 5px; 
    background-color: #000; 
}
.section-title.white{
    color: $white;
}
.section-title.white::after{
    background-color: white;
}

@media screen and  (max-width: 1800px) {
    .section-title {
        font-size: 54px;
    }
    .dynamic-item.item4 {
        top: 70%;
    }
    
}
@media screen and  (max-width: 1400px) {
    .section-solutions {
        border-radius: 75px;
        padding: 60px 100px;
    }
    .section-solutions .sticky-wrapper{
    }
    .section-title {
        font-size: 42px;
    }
    .dynamic-item.item1 {
        bottom: -10%;
        height: 390px;
    }
    .dynamic-item.item2 {
        height: 180px;
    }
    .dynamic-item.item3 {
        height: 130px;
    }
    .dynamic-item.item4 {
        height: 150px;
        top: 80%;
    }
    .dynamic-item.item5 {
        height: 300px;
    }
    .swiper-nav-btns{
        bottom: 150px;
    }
    .swiper-nav-btns .prev-arrow,
    .swiper-nav-btns .next-arrow{
        width: 80px;
        height: 80px;
    }
    .swiper-nav-btns > div > img{
        width: 55px;
    }
}

@media screen and  (max-width: 800px) {
    .section-solutions{
        padding: 50px 50px;
    }
    .solutions .solution:first-child{
        margin-left: 100px;
    }
    .section-title {
        font-size: 32px;
    }
    .section-solutions .solutions {
        margin-left: 100px;
        gap: 34px;
    }
    .dynamic-item.item1 {
        bottom: 0%;
        height: 300px;
    }
    .dynamic-item.item2 {
        top: 20%;
        height: 140px;
    }
    .dynamic-item.item3 {
        height: 100px;
    }
    .dynamic-item.item4 {
        height: 120px;
        top: 80%;
    }
    .dynamic-item.item5 {
        height: 240px;
    }
}

@media screen and  (max-width: 690px) {
    .swiper-scrollbar{
        display: flex;
        // position: absolute;
        // bottom: 120px;
        width: 80%;
        margin: 20px 20px;
        box-sizing: border-box;
    }
    .swiper-nav-btns{
        display: none;
    }
}

@media screen and  (max-width: 500px) {
    .section-solutions{
        // height: 4600px;
        padding: 40px 40px;
        border-radius: 40px;
    }
    .solutions .solution:first-child{
        margin-left: 0px;
    }
    .section-solutions .sticky-wrapper{
    }
    .dynamic-item.item1 {
        bottom: -5%;
        height: 250px;
    }
    .dynamic-item.item2 {
        top: 20%;
        height: 100px;
    }
    .dynamic-item.item3 {
        top: 45%;
        height: 80px;
    }
    .dynamic-item.item4 {
        height: 100px;
        top: 80%;
    }
    .dynamic-item.item5 {
        height: 200px;
    }
    .section-solutions .sticky-wrapper{
        border-radius: 50px;
    }
    .swiper-scrollbar{
        display: block;
    }
}