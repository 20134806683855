.description-typo {
  padding-top: 50px;
  color: #fff;
  max-width: 680px;
  text-align: justify;
  font-family: Montserrat;
  font-size: 2vw;
  font-style: normal;
  font-weight: 300;
  line-height: 140.625%;
}

.worktemplate-main-container {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  justify-content: space-between;
  align-items: center;
}
.work-diplay-container {
  display: flex;
  margin-left: 20px;
  position: relative;
  margin-top: 50px;
  width: 499px;
  height: 544px;
  padding: 81px 0px 0px 16px;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
  border-radius: 75px;
  border: 1px solid #1a1919;
  background: radial-gradient(
    61.14% 70.71% at 50% 50%,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(26, 26, 26, 0.5) 100%
  );
  backdrop-filter: blur(37.5px);
}

.image-sizing {
  border-end-end-radius: 75px;
}

@media screen and (max-width: 670px) {
  .description-typo {
    font-size: 3vw;
    padding-top: 20px;
  }

  .image-sizing {
    border-end-end-radius: 27.857px;
  }

  .worktemplate-main-container {
    justify-content: center;
  }

  .work-diplay-container {
    border-radius: 27.857px;
    border: 0.371px solid #1a1919;
    background: radial-gradient(
      61.14% 70.71% at 50% 50%,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(26, 26, 26, 0.5) 100%
    );
    backdrop-filter: blur(13.928571701049805px);
  }
}
@media screen and (max-width: 650px) {
  .image-sizing {
    width: 200px;
    height: 220px;
  }
  .work-diplay-container {
    width: 200px;
    height: 220px;
  }
}
