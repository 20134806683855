.work-main-container {
  background-color: black;
  min-height: 100vh;
  height: fit-content;
  color: white;
  border-radius: 100px 100px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  position: relative;
  margin-top: -90px;
  z-index: 0;
  width: 100vw;
}
.work-text-container {
  display: flex;
  width: 80%;
  flex-direction: column;
  margin-top: 50px;
  align-items: start;
  color: #f2f2f2;
  font-family: Montserrat;
  font-size: 4vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
// .header-underline {
//   width: 144px;
//   height: 5px;
//   background-color: white;
//   border-radius: 2.5px;
// }

.ballon-position {
  z-index: -1;
  position: absolute;
  top: 40px;
  right: 40px;
}

@media screen and (max-width: 670px) {
  .ballon-position {
    height: 150px;
    display: none;
  }

  .work-main-container {
    border-radius: 60px 60px 0px 0px;
  }
}

@media screen and (max-width: 450px) {
  .work-main-container {
    border-radius: 35px 35px 0px 0px;
  }

  .work-text-container {
    margin-top: 30px;
  }

  .header-underline {
    width: 35px;
    height: 2px;
    background-color: white;
    border-radius: 2.5px;
  }

  .work-main-container {
    height: fit-content;
    margin-top: -35px;
    z-index: 50;
  }
}

@media screen and  (max-width: 500px) {
  .work-main-container{
      padding: 40px 40px;
      border-radius: 40px;
      box-sizing: border-box;
      margin-top: -200px;
  }
  .quote-container{
    margin-top: 0px;
  }
}
