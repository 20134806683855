.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    height: 200px;
    padding: 0 ;
    width: 100%; 
    
  
    .footer-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        width: 100%;
        padding: 0 20px;
    }
  
    .footer-section {
        flex: 1;
        padding: 0 10px;
  
        &:first-child {
            color: #DBDBDB;
            font-family: Montserrat;
            font-size: 20px;
            font-weight: 500;
        }
  
        &:nth-child(2) {
            text-align: center;
            color: #DBDBDB;
            font-family: "JetBrains Mono";
            font-size: 32px;
        }
  
        &:nth-child(3) {
            text-align: right;
        }
    }
  
    .footer-icons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
  
        img {
            width: 60px;
            height: 60px;
        }
    }
  
    @media (max-width: 992px) {
        .footer-content {
            max-width: 750px;
        }
  
        .footer-section:nth-child(2) {
            font-size: 28px;
        }
  
        .footer-icons img {
            width: 50px;
            height: 50px;
        }
    }
  
    @media (max-width: 768px) {
        height: 150px;
  
        .footer-section {
            &:first-child {
                font-size: 18px;
            }
  
            &:nth-child(2) {
                font-size: 24px;
            }
  
            &:nth-child(3) {
                font-size: 18px;
            }
        }
  
        .footer-icons img {
            width: 40px;
            height: 40px;
        }
    }
  
    @media (max-width: 576px) {
        height: 50px;
  
        .footer-section {
            &:first-child {
                font-size: 6px;
            }
  
            &:nth-child(2) {
                font-size: 12px;
            }
  
            &:nth-child(3) {
                font-size: 12px;
            }
        }
  
        .footer-icons img {
            width: 20px;
            height: 20px;
        }
    }
  }
  